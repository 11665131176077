/** see https://www.cdnfonts.com/din-condensed.font */
@font-face {
  font-family: 'DIN Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('DIN Condensed'),
    url('https://fonts.cdnfonts.com/s/14504/DINCondensed-Bold.woff')
      format('woff');
}


.onTop {
  position: absolute;
  width: 33%;
}